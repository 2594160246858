<template>
  <ModernModalLayout :title="message('title')">
    <v-form>
      <v-text-field
        v-model="id"
        :label="message('label')"
        type="number"
        autofocus
        hide-details="auto"
        outlined
        :error-messages="error"
      />
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-5"
        color="primary"
        depressed
        :disabled="!id || $wait('submit')"
        :loading="$wait('submit')"
        @click="handleSubmit"
      >
        {{ message('actionButton') }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import waitable from '@/utils/mixins/waitable';
import messagable from '@/utils/mixins/messagable';

const MESSAGES = {
  title: 'Перейти по ID',
  label: 'Введите ID',
  actionButton: 'Перейти',
  notFound: 'Не найдено',
  forbidden: 'Не доступно',
  error: 'Ошибка. Попробуйте позже',
};

export default {
  components: {
    ModernModalLayout,
  },

  mixins: [waitable, messagable(MESSAGES)],

  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    id: null,
    error: null,
  }),

  mounted() {
    document.addEventListener('keypress', this.handleEnter);
  },

  beforeDestroy() {
    document.removeEventListener('keypress', this.handleEnter);
  },

  methods: {
    handleEnter(event) {
      if (event.key !== 'Enter') return null;
      event.preventDefault();

      if (!this.id) return null;
      this.handleSubmit();
    },

    async handleSubmit() {
      this.error = null;

      try {
        await this.$loading(this.onSubmit(this.id), 'submit');
        this.$emit('close');
      } catch (error) {
        const status = error?.response?.status;
        const errList = {
          404: this.message('notFound'),
          403: this.message('forbidden'),
        };

        this.error = errList[status] || this.message('error');
      }
    },
  },
};
</script>
